import { IDriverExtended } from "../../../../bffmodels/IDriverExtended";
import { compareStrings } from "../../../../utility/CompareStrings";
import { getAuthSchemeOAuthMapping } from "../ConnectionFunctions";
import {
  getSelectedAuthSchemeProperty,
  getSelectedAuthSchemeValue,
} from "./getSelectedAuthScheme";

/**
 * Returns true if this is an OAuth web driver or the user selected an Auth Scheme option that uses OAuth web.
 * OAuth web drivers and auth schemes display the sign in button.
 */
export function isOAuthWeb(driver: IDriverExtended): boolean {
  const authSchemeProperty = getSelectedAuthSchemeProperty(driver);

  // For BullhornCRM, if a manual OAuth code is entered in the OAuthVerifier field, we need to let the driver handle
  // OAuth on its own.
  const oauthVerifier = driver.advancedProps
    .flatMap((p) => p.properties ?? [])
    .find((p) => compareStrings(p.propertyName, "OAuthVerifier"));

  if (oauthVerifier != null && oauthVerifier.currentValue?.length > 0) {
    return false;
  }

  // If the driver does not have an auth scheme dropdown, use the driver metadata
  // to determine if the driver only supports OAuth web.
  if (authSchemeProperty == null) {
    return driver.isOAuthWeb;
  }

  const authSchemeSelectedValue = getSelectedAuthSchemeValue(driver);

  return compareStrings(
    getAuthSchemeOAuthMapping(driver.name!, authSchemeSelectedValue),
    "OAUTH_WEB",
  );
}
