import { Card, CardBody } from "reactstrap";
import "./FileCard.scss";

interface IFileCard {
  fileName: string;
  handleDeleteClick: () => void;
}

const FileCard = (props: IFileCard) => {
  return (
    <>
      <Card className="file-card" data-testid="file-card">
        <CardBody className="file-card-body">
          <i className="fa fa-regular fa-file-lock file-card-icon"></i>
          <span className="file-name">{props.fileName}</span>
          <i
            className="fa-regular fa-xmark delete-icon"
            data-testid="delete-icon"
            onClick={props.handleDeleteClick}
          ></i>
        </CardBody>
      </Card>
    </>
  );
};

export default FileCard;
