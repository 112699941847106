const WEB = "OAUTH_WEB";
const APP = "OAUTH_APP";

export const AuthSchemeOAuthMapping: Record<string, typeof WEB | typeof APP> = {
  azuread: WEB,
  awsazuread: WEB,
  azuremsi: APP,
  oauth: WEB,
  oauth2: WEB,
  oauthjwt: APP,
  client: APP,
  pkce: WEB,
  gcpinstanceaccount: APP,
  genericoauth: WEB,
  azureserviceprincipal: APP,
  azureadopenid: WEB,
  sharepointoauth: WEB,
  oauthclient: APP,
  oauthimplicit: APP,
  oauthpassword: APP,
  oauthsaml1: APP,
  oauthsaml2: APP,
  azurepassword: APP,
  oauthu2m: WEB, // For Databricks U2M
};
