import { AuthSchemeOAuthMapping, OAuthButton } from "./oauth";
import { ConnectionProperty } from "./";
import CallbackURL from "./CallbackURL";
import { getSettingsPageSubscriptionTab } from "../../../utility/SubscriptionProvider";
import { IConnectionContext } from "./ConnectionContext";
import OracleWalletFileManager from "./oracleWallet/OracleWalletFileManager";
import { compareStrings } from "../../../utility/CompareStrings";
import { ICustomReportParameter, UserRole } from "../../../models";
import { IHierarchyPropertyExtended } from "../../../bffmodels/IHierarchyPropertyExtended";
import { IHierarchyCategoryExtended } from "../../../bffmodels/IHierarchyCategoryExtended";
import { SSHConnectionType } from "./SSHConnectionType";
import { createDeepCopy } from "../../../utility/CreateDeepCopy";
import { IDriverExtended } from "../../../bffmodels/IDriverExtended";
import { store } from "../../../redux/store";
import { isOAuthWeb } from "src/pages/connections/components/oauth/isOAuthWeb";

const ListOfOAuthPropsDrivers: string[] = ["ExactOnline", "Square", "TSheets"];

export function updateRequiredProperties(
  driverProperties: ICustomReportParameter[],
  setRequiredProperties: (requiredProperties: ICustomReportParameter[]) => void,
) {
  const updatedRequiredProperties: any = {};
  driverProperties.forEach((property: any) => {
    updatedRequiredProperties[property.name] = property.required;
  });
  setRequiredProperties(updatedRequiredProperties);
}

// Recursively check all hierarchy properties for AuthScheme
export function checkSelectedHierarchyRulesForAuthScheme(
  connectionContext: IConnectionContext,
  property: IHierarchyPropertyExtended,
) {
  if (property.hierarchyRules) {
    const selectedHierarchyRuleProperties =
      getSelectedHiearchyRuleProperties(property);

    if (
      selectedHierarchyRuleProperties &&
      selectedHierarchyRuleProperties.length !== 0
    ) {
      selectedHierarchyRuleProperties.forEach(
        (hierarchyProperty: IHierarchyPropertyExtended) => {
          checkSelectedHierarchyRulesForAuthScheme(
            connectionContext,
            hierarchyProperty,
          );
        },
      );
    }
  }
}

// Use depth first search to update basic properties and any currently selected hierarchyRule properties
function updateBasicProperty(
  connectionContext: IConnectionContext,
  basicDriverProperties: IHierarchyPropertyExtended[],
  propertyNameToUpdate: string,
  newValue: string,
) {
  basicDriverProperties.forEach((property: IHierarchyPropertyExtended) => {
    const stack = [[property, null]];

    while (stack.length) {
      const [currentProperty] = stack.pop()!;
      if (compareStrings(currentProperty!.propertyName, propertyNameToUpdate)) {
        currentProperty!.currentValue = newValue;
        checkSelectedHierarchyRulesForAuthScheme(
          connectionContext,
          currentProperty!,
        );
      } else if (currentProperty!.hierarchyRules) {
        const selectedHierarchyRuleProperties =
          getSelectedHiearchyRuleProperties(currentProperty!);

        if (
          selectedHierarchyRuleProperties &&
          selectedHierarchyRuleProperties.length !== 0
        ) {
          selectedHierarchyRuleProperties.forEach(
            (child: IHierarchyPropertyExtended) => {
              if (compareStrings(child.propertyName, propertyNameToUpdate)) {
                child.currentValue = newValue;
                checkSelectedHierarchyRulesForAuthScheme(
                  connectionContext,
                  currentProperty!,
                );
              }
              stack.push([child, currentProperty]);
            },
          );
        }
      }
    }
  });

  return basicDriverProperties;
}

// Update property in both basic and advanced property lists
export function updateProperty(
  connectionContext: IConnectionContext,
  propertyName: string,
  propertyValue: string,
) {
  // Find the property and update currentValue in basic property list or any currently selected hierarchy rules
  let newBasicDriverProperties = [...connectionContext.driverInfo!.basicProps];
  newBasicDriverProperties = updateBasicProperty(
    connectionContext,
    newBasicDriverProperties,
    propertyName,
    propertyValue,
  );

  const advProps = { ...connectionContext.driverInfo! };
  advProps.basicProps = newBasicDriverProperties;
  connectionContext.setDriverInfo!(advProps);

  // Find the property and update currentValue in advanced property list
  // Note: AdvancedDriverProperties contains all root properties and hierarchyRule properties that can be found in the basicDriverProperties
  const newAdvancedDriverProperties = [
    ...connectionContext.driverInfo!.advancedProps,
  ];
  const advancedDriverCategoryIndex = newAdvancedDriverProperties.findIndex(
    (category: IHierarchyCategoryExtended) => {
      return category.properties!.some(
        (advancedProperty: IHierarchyPropertyExtended) => {
          return compareStrings(advancedProperty.propertyName, propertyName);
        },
      );
    },
  );

  const advancedDriverCategory =
    newAdvancedDriverProperties[advancedDriverCategoryIndex];
  const advancedDriverPropertyIndex: number =
    advancedDriverCategory?.properties!.findIndex(
      (advancedProperty: IHierarchyPropertyExtended) =>
        compareStrings(advancedProperty.propertyName, propertyName),
    );
  const advancedDriverProperty =
    advancedDriverCategory?.properties![advancedDriverPropertyIndex];
  if (advancedDriverProperty) {
    advancedDriverProperty.currentValue = propertyValue;
    newAdvancedDriverProperties[advancedDriverCategoryIndex].properties![
      advancedDriverPropertyIndex
    ] = advancedDriverProperty;

    const advProps = { ...connectionContext.driverInfo! };
    advProps.advancedProps = newAdvancedDriverProperties;
    connectionContext.setDriverInfo!(advProps);
  }
}

// The onChange for ConnectionProperty.  This will be called anytime someone updates a field, and should update both basic and advanced versions of the property
export function inputUpdated(
  event: any,
  connectionContext: IConnectionContext,
  isBoolean?: boolean,
) {
  if (connectionContext.updatePreventNavigation) {
    connectionContext.updatePreventNavigation(
      true,
      "Unsaved Changes",
      "If you leave this page, any unsaved changes will be lost. Are you sure you want to leave?",
    );
  }

  // Normalize and toggle propertyValue for boolean properties
  if (isBoolean) {
    const propertyValue = compareStrings(event.target.type, "switch")
      ? event.target.switch
      : event.target.value.toLowerCase();

    if (propertyValue === false || compareStrings(propertyValue, "false")) {
      event.target.value = true;
    } else if (
      propertyValue === true ||
      compareStrings(propertyValue, "true") ||
      compareStrings(propertyValue, "on")
    ) {
      event.target.value = false;
    }
  }

  updateProperty(connectionContext, event.target.name, event.target.value);
  connectionContext.setUnsavedChanges!(true);
}

// The onChange for dynamic connection properties that populate after OAuth is complete. The selector we use handles events differently than other selectors,
// and the range of data is narrower than the standard function above, since it is always a multi-option selector.
export function dynamicInputUpdated(
  connectionContext: IConnectionContext,
  propertyName: string,
  propertyValue: string,
) {
  if (connectionContext.updatePreventNavigation)
    connectionContext.updatePreventNavigation(
      true,
      "Unsaved Changes",
      "If you leave this page, any unsaved changes will be lost. Are you sure you want to leave?",
    );

  updateProperty(connectionContext, propertyName, propertyValue);
  connectionContext.setUnsavedChanges!(true);
}

// Handles user selections for the SSH type selector, which doesn't use the same event structure as other properties and requires custom field name handling.
export function sshTypeUpdated(
  connectionContext: IConnectionContext,
  type: SSHConnectionType,
) {
  if (type === SSHConnectionType.Direct) {
    updateProperty(connectionContext, "UseSSH", "false");
  } else if (type === SSHConnectionType.SSHTunnel) {
    updateProperty(connectionContext, "UseSSH", "true");
  }

  connectionContext.setUnsavedChanges!(true);
}

// Recursively display hierarchy rules for any properties that have them
export function displayHierarchyRules(
  displayedBasicDriverProperties: IHierarchyPropertyExtended[],
  property: IHierarchyPropertyExtended,
) {
  if (property.hierarchyRules) {
    const selectedHierarchyRuleProperties =
      getSelectedHiearchyRuleProperties(property);

    if (
      selectedHierarchyRuleProperties &&
      selectedHierarchyRuleProperties.length !== 0
    ) {
      selectedHierarchyRuleProperties.forEach(
        (hierarchyProperty: IHierarchyPropertyExtended) => {
          if (
            compareStrings(hierarchyProperty.display, "requiredbasic") ||
            compareStrings(hierarchyProperty.display, "unrequiredbasic")
          ) {
            displayedBasicDriverProperties.push(hierarchyProperty);
            displayHierarchyRules(
              displayedBasicDriverProperties,
              hierarchyProperty,
            );
          }
        },
      );
    }
  }
}

// Create a list of basic driver properties and any currently selected hierarchyRule properties
export function getDisplayedBasicDriverProperties(
  connectionContext: IConnectionContext,
  location: string,
) {
  let basicDriverProperties = createDeepCopy(
    connectionContext.driverInfo.basicProps,
  );

  if (hasSSH(connectionContext.driverInfo)) {
    basicDriverProperties = addSSHPropertiesToBasic(
      basicDriverProperties,
      connectionContext,
    );
  }

  const basicDriverPropsInOrder: IHierarchyPropertyExtended[] =
    buildBasicPropertyHierarchy(basicDriverProperties);

  // Display all basic properties in-order
  const displayedBasicDriverProperties: JSX.Element[] = [];
  basicDriverPropsInOrder.forEach(
    (basicProperty: IHierarchyPropertyExtended) => {
      const display = basicProperty.display;

      // For basic driver properties, only properties with RequiredBasic/UnrequiredBasic are displayed
      if (
        (display && compareStrings(display, "requiredbasic")) ||
        compareStrings(display, "unrequiredbasic")
      ) {
        displayedBasicDriverProperties.push(
          <ConnectionProperty
            data-testid={`property-${basicProperty.propertyName}`}
            key={basicProperty.propertyName!}
            location={location}
            property={basicProperty}
            tab="basic"
          />,
        );
      }
    },
  );

  // Add the OAuth sign in button to the bottom of the connection properties
  // Add the Callback URL field above OAuthClientID and below AuthScheme property
  const index = displayedBasicDriverProperties.findIndex(
    (connectionProperty) => {
      return compareStrings(
        connectionProperty.props.property.propertyName,
        "OAuthClientId",
      );
    },
  );

  const shouldShowCallbackURL = isOAuthWeb(connectionContext.driverInfo);

  if (index > -1 && shouldShowCallbackURL) {
    const callbackURL = <CallbackURL key="CallbackURL" />;
    displayedBasicDriverProperties.splice(index, 0, callbackURL);
  }

  const oauthButton = <OAuthButton key="OauthButton" />;
  displayedBasicDriverProperties.push(oauthButton);

  if (compareStrings(connectionContext.driverType, "OracleOci")) {
    const oracleWalletFilePicker = (
      <OracleWalletFileManager
        key="OracleWalletFilePicker"
        onFileChangeCallback={(file: File | null) => {
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              let resultString = reader.result as string;
              resultString = resultString.split("base64,")[1];
              connectionContext?.setSelectedFile!(resultString);
            };
          }
          connectionContext.setWalletFilePresent!(true);
        }}
        handleDeleteClick={() => {
          connectionContext?.setSelectedFile("");
          connectionContext?.setWalletFilePresent(false);
        }}
        walletFilePresent={connectionContext.walletFilePresent}
        connectionId={connectionContext.connectionId}
        formRef={connectionContext.formRef}
        driverType={connectionContext.driverType}
        connectionInfo={connectionContext.connectionInfo}
        hasUserConnectionString={connectionContext.hasUserConnectionString}
      />
    );
    displayedBasicDriverProperties.push(oracleWalletFilePicker);
  }

  return displayedBasicDriverProperties;
}

/** Manually insert SSH properties into the basic array, since they are categorized as advanced by default. */
function addSSHPropertiesToBasic(
  basicDriverProperties: IHierarchyPropertyExtended[],
  connectionContext: Partial<IConnectionContext>,
) {
  const sshPropCategory = connectionContext.driverInfo?.advancedProps.find(
    (prop) => prop.name === "SSH",
  );

  if (sshPropCategory?.properties) {
    let sshProperties: IHierarchyPropertyExtended[] = [];

    // If UseSSH is enabled, add the properly-flagged SSH properties to the basic props. If it's not enabled, don't add anything.
    if (
      sshPropCategory.properties?.find((prop) => prop.propertyName === "UseSSH")
        ?.currentValue === "true"
    ) {
      sshProperties = setSSHPropertyFlags(sshPropCategory.properties);
    }

    basicDriverProperties = [...basicDriverProperties, ...sshProperties];
  }
  return basicDriverProperties;
}

function setSSHPropertyFlags(properties: IHierarchyPropertyExtended[]) {
  let requiredProps = ["SSHServer", "SSHPort"];
  const currentAuthMode = properties.find(
    (property) => property.propertyName === "SSHAuthMode",
  )?.currentValue;

  if (currentAuthMode === "Password") {
    requiredProps = [...requiredProps, "SSHUser", "SSHPassword"];
  } else if (currentAuthMode === "Public_Key") {
    requiredProps = [...requiredProps, "SSHUser", "SSHClientCert"];
  }

  const sshProperties = properties
    // Exclude SSHClientCertSubject because it should stay in Advanced, and SSHServerFingerprint until CLOUD-11995 is done.
    .filter(
      (property) =>
        property.propertyName !== "SSHClientCertSubject" &&
        property.propertyName !== "SSHServerFingerprint",
    )
    .map((property) => {
      // Assign each property `visible = false` so they don't appear in the Authentication card
      property.visible = false;

      if (requiredProps.includes(property.propertyName!)) {
        property.display = "RequiredBasic";
      } else {
        property.display = "UnrequiredBasic";
      }
      return property;
    });
  return sshProperties;
}

export function buildBasicPropertyHierarchy(
  basicDriverProperties: IHierarchyPropertyExtended[],
): IHierarchyPropertyExtended[] {
  const basicDriverPropsInOrder: IHierarchyPropertyExtended[] = [];
  basicDriverProperties.forEach((basicProperty: IHierarchyPropertyExtended) => {
    const display = basicProperty.display;

    // For basic driver properties, only properties with RequiredBasic/UnrequiredBasic are displayed
    if (
      (display && compareStrings(display.toLowerCase(), "requiredbasic")) ||
      compareStrings(display?.toLowerCase(), "unrequiredbasic")
    ) {
      basicDriverPropsInOrder.push(basicProperty);
      displayHierarchyRules(basicDriverPropsInOrder, basicProperty);
    }
  });

  return basicDriverPropsInOrder;
}

export function shouldSaveOAuthProps(connectionContext: IConnectionContext) {
  return (
    ListOfOAuthPropsDrivers.includes(connectionContext.driverType) ||
    (connectionContext.oauthProps &&
      connectionContext.oauthProps["OAuthExpiresIn"] === "-1")
  );
}

export function getUpgradeLink(instance: any) {
  const upgradeLink =
    store.getState().user.role === UserRole.Admin ? (
      <a
        className="upgrade-link"
        onClick={() => {
          instance.router.navigate(
            "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
          );
        }}
      >
        Upgrade
      </a>
    ) : (
      "Upgrade"
    );

  return upgradeLink;
}

export function getAuthSchemeOAuthMapping(
  driverType: string,
  authScheme: string | null | undefined,
) {
  if (authScheme == null) {
    return null;
  }
  if (
    compareStrings(authScheme, "azureserviceprincipal") &&
    compareStrings(driverType, "databricks")
  ) {
    return null;
  }
  // The PayPal driver has an auth scheme field with only a single value of OAuth.
  // The PayPal OAuth flow is a OAuth client credentials flow and should not display the sign in button.
  if (compareStrings(driverType, "paypal")) {
    return null;
  }
  return AuthSchemeOAuthMapping[authScheme] ?? null;
}

function getSelectedHiearchyRuleProperties(
  property: IHierarchyPropertyExtended,
) {
  let selectedHierarchyRuleProperties =
    property.hierarchyRules![
      Object.keys(property.hierarchyRules!).find((key) =>
        compareStrings(key, property.currentValue),
      )!
    ];

  if (!selectedHierarchyRuleProperties) {
    selectedHierarchyRuleProperties = property.hierarchyRules!["<other>"];
  }

  return selectedHierarchyRuleProperties;
}

export function hasSSH(driverInfo: IDriverExtended) {
  return driverInfo?.advancedProps?.some((prop) => prop.name === "SSH");
}
